<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
    </div>
    <Table
      v-if="getTableOfVotingCommissioner.table"
      :content="getTableOfVotingCommissioner.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/elements/Table';

export default {
  components: { Table },
  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getTableOfVotingCommissioner');
  },

  computed: {
    ...mapGetters(['getTableOfVotingCommissioner']),
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfVotingCommissioner');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfVotingCommissioner');
    },
  },
};
</script>

<style lang="sass" scoped>
.table__header_bar.input-search__form
  @include xs
    width: 300px
</style>
